import {PublicClientApplication} from "@azure/msal-browser";

const Mode = {
    Production: 0,
    Development: 1
}
export const currentMode = Mode.Development;

export const mode = "production";

export const msalConfig = {
    auth: {
        clientId: "aadf56ce-b8c9-474f-86e0-a52cb866a192",
        authority: "https://login.microsoftonline.com/organizations",
        redirectUri: currentMode === Mode.Production ? "https://setup.sf-app.de" : "http://localhost:3000",
    },
    cache: {
        /* WICHTIG! Die Token werden in einem separaten Tab erzeugt, deshalb müssen die MSAL Clients den localStorage verwenden.
        * Alternativ wird der sessionStorage verwendet, welcher nicht in anderen Tabs verfügbar ist. */
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const newMsalClient = () => new PublicClientApplication(msalConfig)
export const scopes = ["User.Read", "Directory.Read.All"]
export const loginRequest = {
    scopes: scopes
}
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};