import * as React from "react";
import {useState} from "react";
import {
    Checkbox,
    ConstrainMode,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DocumentCard,
    DocumentCardActivity,
    DocumentCardDetails,
    DocumentCardLogo,
    DocumentCardTitle,
    Dropdown,
    FontIcon,
    IColumn,
    IconButton,
    IDropdownOption,
    Label,
    MarqueeSelection,
    memoizeFunction,
    mergeStyles,
    mergeStyleSets,
    PrimaryButton,
    Selection,
    SelectionMode,
    Separator,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    TooltipDelay,
    TooltipHost
} from "@fluentui/react";
import {useConst} from "@fluentui/react-hooks";
import {getExtension, isEmail, SfCSVDate} from "../Utils";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import StyledDropzone from "react-dropzone";
import {SignInButton} from "./AzureSignIn";
import {AADImportUsersButton} from "./AADImportUsersButton";
import {SignOutButton} from "./AzureSignOut";
import {InteractionStatus} from "@azure/msal-browser";
import {ReduxDispatch, ReduxQuery, store} from "./ReduxStore";
import {CommonProps, GroupProps, LineProps, SetupState, UserProps} from "./SetupState";
import {useUrlSearchParams} from "use-url-search-params";
import FileSaver from "file-saver";
import {readString} from "react-papaparse";
import {parsePhoneNumber, PhoneNumber} from "libphonenumber-js";
import {ParseResult} from "papaparse";

type Props = {
    setup: SetupState
}

/**
 * Formularabschnitt mit Konfigurationsschritten
 * @param p
 * @constructor
 */
export const PcSetupUserForm: React.FC<Props> = (p: Props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const username = (accounts[0] && accounts[0].name) || "";

    const [commonDefaults, setCommonDefaults] = React.useState<CommonProps>({
        password: undefined, faxSender: undefined, forwardingTarget: undefined
    });

    const [urlParams] = useUrlSearchParams({p: "", prj: ""}, {p: String, prj: String});
    const project = urlParams.prj as string;
    const [files, setFiles] = React.useState<File[]>();

    const [importCsv, setImportCsv] = useState<boolean>(false);
    const [importConfig, setImportConfig] = useState<boolean>(false);

    const userSelection = useConst(
        () => new Selection({
            onSelectionChanged: () => {}
        })
    )
    const groupSelection = useConst(
        () => new Selection({
            onSelectionChanged: () => {}
        })
    )

    const groupColumns: IColumn[] = [
        {
            key: "gcol0",
            name: "Gültig",
            fieldName: "valid",
            isIconOnly: true,
            minWidth: 20, maxWidth: 20,
            onRender: (row: UserProps, index) => {
                return row.isValid !== undefined && <TooltipHost
                    content={row.isValid ? "" : "Der Datensatz dieser Zeile enthält ungültige Angaben"}>
                    <FontIcon iconName={row.isValid ? "CheckMark" : "IncidentTriangle"}
                              style={{fontSize:16,color: row.isValid ? "#7fba00" : "#ce1c1c", verticalAlign:"middle"}}/>
                </TooltipHost>
            },
        },
        {
            key: "gcol1",
            name: "Name",
            fieldName: "name",
            isIconOnly: false,
            isResizable: true,
            minWidth: 160, maxWidth: 160,
            onRender: renderTextField("groups","name", undefined, onValidateName),
        },
        {
            key: 'gcol2',
            name: ' Intern',
            iconName: "Phone",
            fieldName: 'intPhoneNumber',
            minWidth: 54, maxWidth: 54, isResizable: true,
            isMultiline: false,
            onRender: (row: GroupProps, index) => {
                return <TooltipHost content={"Interne Rufnummern sind mindestens zweistellig und sollten bezüglich der Länge sich nicht mit Ortsnetzrufnummern überschneiden."}>
                    {renderTextField("groups","intPhoneNumber", undefined, onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "intPhoneNumber", "other"), undefined, undefined,
                        (e) => {
                        if (typeof index === "number" && onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "extPhoneNumber", "other")(e.target.value) === undefined) {
                            const g = p.setup.groups[index];
                            if (g) {
                                g.extPhoneNumber = e.target.value;
                                ReduxDispatch.setGroup(index, g);
                            }
                        }
                    })(row, index)}
                </TooltipHost>;
            },
            isPadded: true,
        },
        {
            key: 'gcol3',
            name: ' Externe Durchwahl',
            iconName: "Phone",
            isMultiline: true,
            fieldName: 'extPhoneNumber',
            minWidth: 190, maxWidth: 220, isResizable: true,
            isCollapsible: true,
            onRender: (row: GroupProps, index) => {
                return <TooltipHost content={"Die externe Durchwahl ist optional, kann also leer sein. Sie darf jedoch nicht mehrfach vergeben werden."}>
                    {renderTextField("groups","extPhoneNumber", undefined, onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "extPhoneNumber", "other"),
                        undefined, undefined, undefined, p.setup.lineProperties.extBase + ' –')(row, index)}
                </TooltipHost>;
            },
            isPadded: true,
        },
        {
            key: "gcol4",
            name: "Klingelstrategie",
            fieldName: "strategy",
            minWidth: 300, maxWidth: 300,
            onRender: (row: GroupProps, index) => {
                return <Dropdown options={ringingStrategy} selectedKey={row.strategy} onChange={(e, option, i) => {
                    if (typeof index === "number" && option?.key) {
                        let g = p.setup.groups[index];
                        // @ts-ignore
                        g.strategy = option.key || "ringall";
                        ReduxDispatch.setGroup(index, g);
                    }
                }}/>
            }
        },
    ]

    const groupDropdownOptions = p.setup.groups.filter((g) => {return g.isValid}).map((g, index) => {
        return {
            index: index,
            key: g.id,
            text: g.name
        } as IDropdownOption;
    })
    /*console.log("Dropdown Options: " + JSON.stringify(groupDropdownOptions));
    console.log("Selection: " + Array.from<string>(p.setup.users[0]?.groupMembership || []))*/

    const userColumns: IColumn[] = [
        {
            key: 'column0',
            name: 'Gültig',
            fieldName: 'valid',
            isIconOnly: true,
            minWidth: 20, maxWidth: 20,
            onRender: (row: UserProps, index) => {
                return row.isValid !== undefined && <TooltipHost
                    content={row.isValid ? "" : "Der Datensatz dieser Zeile enthält ungültige Angaben"}>
                    <FontIcon iconName={row.isValid ? "CheckMark" : "IncidentTriangle"}
                              style={{fontSize:16,color: row.isValid ? "#7fba00" : "#ce1c1c", verticalAlign:"middle"}}/>
                </TooltipHost>
            },
        },
        {
            key: 'column1',
            name: 'Login',
            fieldName: 'login',
            isIconOnly: false,
            isSortedDescending: false,
            minWidth: 54, maxWidth: 54, isSorted: true,
            onRender: (row: UserProps, index) => {
                return <TooltipHost content={"Bei der Login-ID handelt es sich um eine numerische Zeichenfolge, mit der sich Benutzer anmelden können. Wir empfehlen, als Login-ID die interne Telefonnummer zu verwenden."}>
                    {
                        renderTextField("users","login", undefined, onValidateLogin(p.setup.users),
                        (e) => {
                            if (!e.currentTarget.value && typeof index === "number" && index > 0) {
                                const prevLoginId = p.setup.users[index - 1].login;
                                if (!isNaN(Number(prevLoginId))) {
                                    const s = p.setup.users[index];
                                    s.login = (Number(prevLoginId) + 1).toString();
                                    ReduxDispatch.setUser(index, s);
                                }
                            }
                        },() => {},
                        (e) => {
                            if (typeof index === "number") {
                                //const u = p.setup.getUser(index);
                                const u = p.setup.users[index];
                                //console.log("USER:" + JSON.stringify(u))
                                if (u) {
                                    u.intPhoneNumber = onBlurSuggestInternalNumber(e.target.value, row).intPhoneNumber;
                                    u.extPhoneNumber = onBlurSuggestInternalNumber(e.target.value, row).intPhoneNumber;
                                    ReduxDispatch.setUser(index, u);
                                }
                            }
                        }/*,undefined,"number"*/)(row, index)
                    }
                    </TooltipHost>
            },
        },
        {
            key: 'column2',
            name: 'Nachname',
            fieldName: 'familyName',
            minWidth: 100, maxWidth: 150, isResizable: true, isSorted: false,
            sortAscendingAriaLabel: 'Alphabetisch sortiert (A-Z)',
            sortDescendingAriaLabel: 'Umgekehrt alphabetisch sortiert (Z-A)',
            onRender: renderTextField("users", "familyName", "Nachname", onValidateName),
            isPadded: true,
        },
        {
            key: 'column3',
            name: 'Vorname',
            fieldName: 'firstName',
            minWidth: 100, maxWidth: 150, isResizable: true, isSorted: false,
            sortAscendingAriaLabel: 'Alphabetisch sortiert (A-Z)',
            sortDescendingAriaLabel: 'Umgekehrt alphabetisch sortiert (Z-A)',
            onRender: renderTextField("users","firstName", "Vorname", onValidateName),
            isPadded: true,
        },
        {
            key: 'column4',
            name: 'E-Mail',
            fieldName: 'email',
            minWidth: 150, maxWidth: 200, isResizable: true,
            isRowHeader: true,
            onRender: renderTextField("users","email", "E-Mail", onValidateEmail, undefined, undefined, undefined, undefined,"email"),
            isPadded: true,
        },
        {
            key: 'password',
            name: 'Kennwort',
            fieldName: 'password',
            minWidth: 100, maxWidth: 100, isResizable: true,
            isRowHeader: true,
            onRender: renderTextField("users","password", "Kennwort", onValidatePassword),
            isPadded: true,
        },
        {
            key: 'column5',
            name: ' Intern',
            iconName: "Phone",
            fieldName: 'primary-int',
            minWidth: 54, maxWidth: 54, isResizable: true,
            isMultiline: true,
            onRender: (row: UserProps, index) => {
                return <TooltipHost content={"Interne Rufnummern sind mindestens zweistellig und sollten bezüglich der Länge sich nicht mit Ortsnetzrufnummern überschneiden."}>
                    {renderTextField("users", "intPhoneNumber", undefined, onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "intPhoneNumber", "other"))(row, index)}
                </TooltipHost>;
            },
            isPadded: true,
        },
        {
            key: 'column6',
            name: ' Externe Durchwahl',
            iconName: "Phone",
            isMultiline: true,
            fieldName: 'primary-ext',
            minWidth: 190, maxWidth: 220, isResizable: true,
            isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <TooltipHost content={"Die externe Durchwahl ist optional, kann also leer sein. Sie darf jedoch nicht mehrfach vergeben werden."}>
                    {renderTextField("users","extPhoneNumber", undefined, onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "extPhoneNumber", "other"),
                        undefined, undefined, undefined, p.setup.lineProperties.extBase + ' –')(row, index)}
                </TooltipHost>;
            },
            isPadded: true,
        },
        {
            key: 'groupSelection',
            name: 'Gruppenmitglied',
            fieldName: 'group-member',
            minWidth: 190, maxWidth: 220, isResizable: true,
            isCollapsible: true,
            onRender: (row: UserProps, index) => {
                if (index === undefined) return <></>
                return <Dropdown multiSelect options={groupDropdownOptions} selectedKeys={
                        Array.from<string>(p.setup.users[index]?.groupMembership || [])
                    } onChange={(e, option) => {
                        if (typeof index === "number") {
                            const u = p.setup.users[index];
                            if (u && typeof option?.key === "string") {
                                if (!u.groupMembership) {
                                    u.groupMembership = new Set();
                                }
                                if (option?.selected) {
                                    u.groupMembership?.add(option.key);
                                    ReduxDispatch.setUser(index, u);
                                } else {
                                    // unselected
                                    if (u.groupMembership?.delete(option.key)) {
                                        ReduxDispatch.setUser(index, u);
                                    }
                                }
                            }
                        }
                    }}/>;
            },
            isPadded: true,
        },
        {
            key: 'column7',
            name: 'Anklopfen',
            fieldName: 'mwi',
            minWidth: 70, maxWidth: 90, isResizable: true, isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <Checkbox checked={row.mwi} key={index + "_7_" + row.mwi} onChange={
                    (e, value) => {
                        if (typeof index === "number") {
                            const u = p.setup.users[index];
                            if (u) {
                                u.mwi = value;
                                ReduxDispatch.setUser(index, u);
                            }
                        }
                    }
                }/>;
            },
        },
        {
            key: 'column8',
            name: 'Fax-Absender',
            fieldName: 'fax-sender',
            minWidth: 100, maxWidth: 100, isResizable: true, isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <TooltipHost content={"Der Absendername, der in der Kopfzeile eines ausgehenden Faxes angezeigt wird."}>
                    <TextField value={row.faxSender}
                               tabIndex={0}
                               onChange={() => {
                               }}
                               onBlur={() => {
                               }}
                    />
                </TooltipHost>;
            },
        },
        {
            key: 'column9',
            name: 'Lizenztyp',
            fieldName: 'license-type',
            minWidth: 110, maxWidth: 110,
            onRender: (row: UserProps, index) => {
                return <TooltipHost delay={TooltipDelay.long} content={licenseTypeDescription}>
                    <DefaultButton toggle checked={row.license === "light"}
                                      text={row.license === "light" ? "Light" : "User"}
                                      iconProps={{iconName: row.license === "light" ? "Devices3" : "Contact"}}
                                      onClick={() => {
                                          if (typeof index === "number") {
                                              const u = p.setup.users[index];
                                              if (u) {
                                                  u.license = u.license === "light" ? "standard" : "light";
                                                  ReduxDispatch.setUser(index, u);
                                              }
                                          }
                                      }}
                /></TooltipHost>
            },
        },
        {
            key: 'column10',
            name: 'Softphone-Lizenz',
            fieldName: 'premium-license',
            minWidth: 70, maxWidth: 90, isResizable: true, isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <TooltipHost content={"Schaltet Premium-Funktionen im UCC-Client frei, die das Telefonieren am PC sowie die Steuerung von Anrufen am PC erlauben."}>
                    <Checkbox checked={row.softphone} key={index + "_10_" + row.softphone} onChange={
                    (e, value) => {
                        if (typeof index === "number") {
                            const u = p.setup.users[index];
                            if (u) {
                                u.softphone = value;
                                ReduxDispatch.setUser(index, u);
                            }
                        }
                    }
                }/></TooltipHost>;
            },
        },
        {
            key: 'column11',
            name: 'Terminalserver-Lizenz',
            fieldName: 'ts-license',
            minWidth: 70, maxWidth: 90, isResizable: true, isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <Checkbox checked={row.terminalserver} key={index + "_11_" + row.terminalserver} onChange={
                    (e, value) => {
                        if (typeof index === "number") {
                            const u = p.setup.users[index];
                            if (u) {
                                u.terminalserver = value;
                                ReduxDispatch.setUser(index, u);
                            }
                        }
                    }
                }/>;
            },
        },
        {
            key: 'column12',
            name: 'Admin',
            fieldName: 'admin',
            minWidth: 32, maxWidth: 32, isResizable: true, isCollapsible: true,
            onRender: (row: UserProps, index) => {
                return <Checkbox checked={row.isAdmin} key={index + "_12_" + row.isAdmin} onChange={
                    (e, value) => {
                        if (typeof index === "number") {
                            const u = p.setup.users[index];
                            if (u) {
                                u.isAdmin = value;
                                ReduxDispatch.setUser(index, u);
                            }
                        }
                    }
                }/>;
            }
        },
    ]


    const partnerId: string = urlParams.p as string;
    const istPartner: boolean = !(partnerId && partnerId.length > 0);
    let basePhoneNumber: PhoneNumber | undefined = undefined;
    try {
        basePhoneNumber = parsePhoneNumber(p.setup.lineProperties.extBase);
    } catch (e) {}
    ///////////////////////////////////////////////////////////////
    //////////////////////////////////// RENDERING ////////////////
    ///////////////////////////////////////////////////////////////

    return <div style={{padding: "30px"}}>
        <h2>Schritt 1: Konfiguration der internen und externen Rufnummern</h2>
        <div className={defaultContentAreaStyleClass}>
            <p>
                Interne Rufnummern sind von externen Rufnummern zu unterscheiden: Sie sind völlig unabhängig voneinander und müssen nicht gleich lauten.<br/>
                Jeder anzulegende Benutzer und jede anzulegende Gruppe muß mindestens eine interne Rufnummer besitzen.<br/>
                Über die interne Rufnummer werden Teilnehmer innerhalb des Unternehmens erreicht.
            </p>
            <p>
                <b>Wichtig:</b> Interne Rufnummern dürfen sich nicht mit Notrufnummern (z.B. 110 oder 112) überschneiden und nicht mit einer 0 beginnen.
            </p>
            <p>
                Externe Rufnummern sind zwar optional, aber für die Erreichbarkeit von Teilnehmern oder Gruppen von "Außen" notwendig – also für externe Anrufer, außerhalb Ihres Unternehmens<br/>
                Eine externe Rufnummer oder ein Rufnummernblock wurde Ihnen von Ihrem Telefonanbieter zugeteilt. Üblicherweise werden Rufnummernblöcke dabei in Größen von 0–29 (30er Block), 0–69 (70er Block), 0–99 (100er Block), 0–299 (300er Block), 0–499 (500er Block) oder 0–999 (1.000er Block) vergeben.<br/>
            </p>
        </div>
        <Stack horizontal style={{marginBottom:"50px"}}>
            <Stack className={defaultContentAreaStyleClass}>
                <Label required>Interner Rufnummernbereich <TooltipHost content={"Jedem Benutzer muß zwingend mindestens eine interne Rufnummer zugeordnet werden. Nach der Erstkonfiguration können Benutzern auch zusätzliche Rufnummern zugewiesen werden. Interne Rufnummern müssen mindestens zweistellig sein und dürfen nicht mit einer Null beginnen."}><FontIcon iconName={"Info"}/></TooltipHost></Label>
                <Stack horizontal>
                    <TextField underlined prefix={"von:"} size={1} value={p.setup.lineProperties.intFrom}
                               onChange={(e, text) => {
                                   (text !== undefined) &&
                                   ReduxDispatch.setLines({...p.setup.lineProperties, intFrom: text})
                               }}
                               onBlur={ReduxDispatch.validateAll}
                               errorMessage={onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "intPhoneNumber", "from")(undefined)}
                    />&nbsp;
                    <TextField underlined prefix={"bis:"} size={3} value={p.setup.lineProperties.intTo}
                               onChange={(e, text) => {
                                   (text !== undefined) && ReduxDispatch.setLines({...p.setup.lineProperties, intTo: text})
                               }}
                               onBlur={ReduxDispatch.validateAll}
                               errorMessage={onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "intPhoneNumber", "to")(undefined)}
                    />
                </Stack>
            </Stack>
            &nbsp;
            <Stack>
                <Label>Externe Rufnummern <TooltipHost content={"Die Konfiguration externer Rufnummern ist optional. Mit anderen Worten: Benutzer müssen nicht zwingend per Durchwahl erreichbar sein. Bitte geben Sie Ihre Stammrufnummer im internationalen Rufnummernformat ein (z.B. +49 1234 5678)"}><FontIcon iconName={"Info"}/></TooltipHost></Label>
                <Stack horizontal>
                    <TextField prefix={"Stammrufnummer:"} value={p.setup.lineProperties.extFormattedNumber} suffix={"-"} underlined
                               onChange={(e, text) => {
                                   if (text !== undefined) {
                                       ReduxDispatch
                                           .setLines({...p.setup.lineProperties, extFormattedNumber: formatParticipantNumber(stripPhoneNumberOfNonDigits(text))})
                                   }
                               }} onGetErrorMessage={onValidateBaseNumber}
                               onBlur={(e) => {
                                   try {
                                       const pn = parsePhoneNumber(p.setup.lineProperties.extFormattedNumber);
                                       const extLV = pn.countryCallingCode;
                                       const extOVArr = pn.formatInternational().replace("+" + extLV as string,"").match(/\d+/);
                                       const extOV = extOVArr && extOVArr.length > 0 ? extOVArr[0] : "";
                                       const extBase = pn.nationalNumber.replace(extOV as string, "");
                                       ReduxDispatch.setLines({...p.setup.lineProperties,
                                               extLV: extLV as string,
                                               extOV: extOV as string,
                                               extBase: extBase})
                                   } catch (e) {}
                               }}
                    />
                    <TextField value={p.setup.lineProperties.extFrom} size={1} underlined
                               onChange={(e, text) => {
                                   (text !== undefined) &&
                                   ReduxDispatch.setLines({...p.setup.lineProperties, extFrom: text})
                               }}
                               onBlur={ReduxDispatch.validateAll}
                               errorMessage={onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "extPhoneNumber", "from")(undefined)}/>
                    -
                    <TextField value={p.setup.lineProperties.extTo} size={3} underlined
                               onChange={(e, text) => {
                                   (text !== undefined) &&
                                   ReduxDispatch.setLines({...p.setup.lineProperties, extTo: text})
                               }}
                               onBlur={ReduxDispatch.validateAll}
                               errorMessage={onValidateNumber(p.setup.lineProperties, p.setup.groups, p.setup.users, "extPhoneNumber", "to")(undefined)}/>
                </Stack>
            </Stack>
        </Stack>
        <h3>(Optional) Benutzer-Standardkonfigurationen</h3>
        <Stack horizontal>
            <Stack className={defaultContentAreaStyleClass}>
                <p>
                Die Standardkonfiguration wird zunächst auf alle Benutzer angewendet.
                Je Benutzer kann später eine abweichende Einstellung gewählt werden.<br/>Die Standardeinstellungen dienen damit ausschließlich dem schnelleren Ausfüllen der Benutzerliste.
                </p>
                <Stack horizontal tokens={{childrenGap:10}}>
                    <Stack.Item>
                        <Label>Benutzerkennwort (für die Installation)</Label>
                        <TextField placeholder={"Vordefiniertes Benutzerkennwort"} value={p.setup.commonDefaults.password} onGetErrorMessage={(t) => {return t ? onValidatePassword(t) : ""}}
                                onChange={(event, text) => {
                                    setCommonDefaults({...commonDefaults, password: text});
                                }}
                                onBlur={() => ReduxDispatch.setCommonPassword(commonDefaults.password)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Fax-Absenderkennung <TooltipHost content={"Der Absendername ist eine beliebige Zeichenfolge, die in der Kopfzeile Ihrer versendeten Faxe angezeigt wird."}><FontIcon iconName={"Info"}/></TooltipHost></Label>
                        <TextField placeholder={"Absendername"} value={p.setup.commonDefaults.faxSender}
                                   onChange={(event, text) => {
                                       setCommonDefaults({...commonDefaults, faxSender: text});
                                   }}
                                   onBlur={() => ReduxDispatch.setCommonFaxSender(commonDefaults.faxSender)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Umleitungsziel bei Nichterreichbarkeit <TooltipHost content={"Geben Sie eine interne oder externe Rufnummer ein, auf die normalerweise umgeleitet werden soll, wenn ein Benutzer nicht erreichbar ist."}><FontIcon iconName={"Info"}/></TooltipHost></Label>
                        <TextField placeholder={"Standard Umleitungsziel"} value={p.setup.commonDefaults.forwardingTarget}/>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
        <h2>Schritt 2 (optional): Import zukünftiger STARFACE-Benutzer</h2>
        <Stack className={defaultContentAreaStyleClass}>
            Sie können Benutzer aus Ihrem Azure Active Directory (AAD) auslesen oder aus einer CSV-Datei importieren.<br/>Falls Sie kein Azure AD oder eine entsprechende CSV-Datei haben, müssen Sie die einzurichtenden Benutzer in der Tabelle (Schritt 3) eingeben.<br/><br/>
            <Stack tokens={{childrenGap:20}} horizontal horizontalAlign={"start"}>
            <Stack.Item shrink>
                <DocumentCard className={boxStyleClass}>
                    <DocumentCardLogo logoIcon={"AADLogo"}/>
                    <DocumentCardTitle title={"Azure AD (AAD)"}/>
                    {isAuthenticated && accessToken && <DocumentCardActivity activity={"Angemeldet"} people={[{name: username, profileImageSrc: ""}]}/>}
                    {!isAuthenticated && inProgress === InteractionStatus.Login && <Spinner size={SpinnerSize.medium} label={"Anmeldung läuft"}/>}
                    <DocumentCardDetails>
                        <Stack horizontal tokens={{childrenGap:5}}>
                        <SignInButton setAccessToken={setAccessToken} disabled={isAuthenticated}/>
                        <SignOutButton setAccessToken={setAccessToken} disabled={!isAuthenticated}/>
                        </Stack>
                        <Separator/>
                    </DocumentCardDetails>
                    <DocumentCardDetails>
                        <AADImportUsersButton accessToken={accessToken} disabled={!isAuthenticated}
                                                                  commonDefaults={p.setup.commonDefaults}
                                                                  setAccessToken={setAccessToken}/>
                    </DocumentCardDetails>
                </DocumentCard>
            </Stack.Item>
                <Stack.Item verticalFill>
                    <DocumentCard className={boxStyleClass}>
                        <DocumentCardLogo logoIcon={"Table"} />
                        <DocumentCardTitle title={"STARFACE CSV-Datei"}/>
                        <DocumentCardDetails>
                            <StyledDropzone maxFiles={1}
                                            onDrop={(acceptedFiles, rejectedFiles, e) => {
                                                setImportCsv(true);
                                                e.preventDefault();
                                                setFiles(acceptedFiles);
                                                if (acceptedFiles[0]) {
                                                    acceptedFiles[0].text().then(text => {
                                                        importFromCSV(text);
                                                        setImportCsv(false);
                                                    });
                                                }
                                            }}>
                                {({getRootProps, getInputProps, isDragActive, isDragAccept}) => (
                                    // Dropzone
                                    <div className={"dropzone"} {...getRootProps()}>
                                        <input {...getInputProps()}/>
                                        {isDragActive && isDragAccept ?
                                            <div className={"dropzone-active"}></div>
                                            :
                                            <Stack verticalAlign={"center"} verticalFill>Datei hier ablegen oder<br/><i>klicken</i> um Datei auszuwählen.</Stack>
                                        }
                                    </div>
                                )}
                            </StyledDropzone>
                            <br/>
                            {importCsv && <Spinner label={"Importiere CSV-Datei..."}/>}
                        </DocumentCardDetails>
                    </DocumentCard>
                </Stack.Item>
            </Stack>
        </Stack>
        <h2>Schritt 3: Konfiguration zukünftiger STARFACE Benutzer und Gruppen</h2>
        {/*                        // Standard CLIP (Externe Rufnummer, Zentrale)*/}
        Tipp: Verwenden Sie in den folgenden Tabellen die <i>Pfeiltasten</i>, um zwischen den Zeilen und Spalten zu navigieren.<br/>Per Maus können Sie mehrere Zeilen markieren und löschen oder Einstellungen auf die Auswahl anwenden.<br/><br/>
        <h3>Gruppen</h3>
        <MarqueeSelection selection={groupSelection} isDraggingConstrainedToRoot={true}>
            <DetailsList items={p.setup.groups}
                         columns={groupColumns}
                         compact={false}
                         selection={groupSelection}
                         selectionPreservedOnEmptyClick={false}
                         layoutMode={DetailsListLayoutMode.fixedColumns}
                         constrainMode={ConstrainMode.unconstrained}
                         isHeaderVisible={true}
                         enterModalSelectionOnTouch={true}
            />
        </MarqueeSelection>
        <Stack horizontal tokens={{childrenGap:10}} style={{marginTop:10}} verticalAlign={"center"}>
            <PrimaryButton styles={{root: {borderRadius: "50%", height: 48, width: 48, minWidth: 0, padding: 0}}}
                           onClick={ReduxDispatch.addGroup} iconProps={{iconName:"AddGroup"}}
                           title={"Weitere Gruppe hinzufügen"}/>
            <DefaultButton styles={defaultButtonStyleSet}
                           onClick={() => {
                               ReduxDispatch.deleteGroups(groupSelection);
                               groupSelection.setAllSelected(false);
                           }} iconProps={{iconName:"UserRemove"}}
                           title={"Ausgewählte Gruppen entfernen"}/>
            <Separator vertical/>
            <p>Lizenzbedarf:&nbsp;
                <b>{ReduxQuery.iQueueLicenseCount()}</b> x iQueue
            </p>
        </Stack>
        <Separator/>
        <h3>Benutzer</h3>
        <MarqueeSelection selection={userSelection} isDraggingConstrainedToRoot={true}>
            <DetailsList
                items={p.setup.users}
                columns={userColumns}
                compact={false}
                /*getKey={(item, index) => {
                    // stringify values used by toggle buttons. This will re-render the row
                    return index + "_" + JSON.stringify([item.mwi, item.softphone, item.terminalserver, item.isAdmin])}
                }*/
                selection={userSelection}
                selectionMode={SelectionMode.multiple}
                selectionPreservedOnEmptyClick={false}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                isHeaderVisible={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                styles={{root: {overflowX: 'scroll'}}}
            />
        </MarqueeSelection>

        <Stack horizontal tokens={{childrenGap:10}} style={{marginTop:10}} verticalAlign={"center"}>
            <PrimaryButton styles={{root: {borderRadius: "50%", height: 48, width: 48, minWidth: 0, padding: 0}}}
                           onClick={ReduxDispatch.addUser} iconProps={{iconName:"AddFriend"}}
                           title={"Weiteren Benutzer hinzufügen"}/>
            <DefaultButton styles={defaultButtonStyleSet}
                           onClick={() => {
                               ReduxDispatch.deleteUsers(userSelection);
                               userSelection.setAllSelected(false);
                           }}
                           iconProps={{iconName:"UserRemove"}}
                           title={"Ausgewählte Benutzer entfernen"}/>
            <Separator vertical/>
            <IconButton iconProps={{iconName: "More"}} title={"Auf ausgewählte Benutzer anwenden"} menuProps={{items: [
                        {key: "mwi", text: "Anklopfen an/aus", iconProps: {iconName: "Ringer"}, onClick: ReduxDispatch.toggleSetting(userSelection,"mwi")},
                        {key: "softphone", text: "Softphone an/aus", iconProps: {iconName: "HeadsetSolid"}, onClick: ReduxDispatch.toggleSetting(userSelection,"softphone")},
                        {key: "terminalserver", text: "Terminalserver an/aus", iconProps: {iconName: "CloneToDesktop"}, onClick: ReduxDispatch.toggleSetting(userSelection,"terminalserver")},
                        {key: "admin", text: "Admin-Rechte an/aus", iconProps: {iconName: "PartyLeader"}, onClick: ReduxDispatch.toggleSetting(userSelection,"isAdmin")}
                    ]}
            }/>
            <Separator vertical/>
            <p>Lizenzbedarf:&nbsp;
                <b>{ReduxQuery.userLicenseCount()}</b> x PBX User, <b>{ReduxQuery.lightLicenseCount()}</b> x PBX User Light, <b>{ReduxQuery.premiumLicenseCount()}</b> x Premium-Lizenz(en), <b>{ReduxQuery.tsLicenseCount()}</b> x Terminalserver-Lizenz(en)
            </p>
        </Stack>
        <h2>Schritt 4 (optional): Individuelle Anmerkungen</h2>
        <Stack className={defaultContentAreaStyleClass}>
            <TextField multiline placeholder={"Teilen Sie uns hier Ihre individuellen Wünsche und Anmerkungen mit."}
                       value={p.setup.comment}
                       onChange={(e, text) => {
                           text ? ReduxDispatch.setComment(text) : ReduxDispatch.setComment(undefined) }
                       } />
        </Stack>
        <h2>Schritt 5: Konfiguration speichern/exportieren</h2>
        <div className={defaultContentAreaStyleClass}>
            <p>Klicken Sie auf Speichern, um Ihre Konfiguration herunterzuladen und diese später fortzusetzen oder sie uns per E-Mail zu senden.</p>
            <PrimaryButton onClick={() => onSave(project)} text={"Speichern"}></PrimaryButton>{istPartner && <>&nbsp;oder&nbsp;
            <DefaultButton onClick={() => ReduxDispatch.showExportUtpDialog(true)} text={"An STARFACE-Installation (User Template PRO) übertragen"}></DefaultButton></>}
        </div>
    </div>
}

/////////////////////////////////////////////////////

const licenseTypeDescription = <>
    <Stack horizontal>
        <Stack.Item grow={1}>
            <h4>User</h4>
            <p>Normale Benutzerlizenz, die ohne Einschränkung die vollständige Basisfunktionalität der STARFACE bietet.</p>
            <p>Geeignet für Mitarbeiter des Unternehmens, die Komfortfunktionen nutzen möchten.</p>
        </Stack.Item>
        <Separator vertical/>
        <Stack.Item grow={1}>
            <h4>Light</h4>
            <p>Benutzer-Lizenz, die sich für die Anbindung von Geräten wie Türsprechstellen, Aufzügen, Faxgeräten oder eingeschränkten Benutzern eignet.</p>
            <p>Einschränkungen:</p>
            <ul>
                <li>Nur <i>ein</i> (1) Telefonendgerät (Tischtelefon, analog oder DECT; kein Softphone)</li>
                <li>Eingeschränkte Ruflisten: Nur jeweils letzter Anruf</li>
                <li>Kein Zugriff auf Weboberfläche</li>
                <li>Nur durch Administrator konfigurierbare Einstellungen</li>
                <li>Light-Benutzer kann nicht Mitglied einer Warteschlange sein</li>
                <li>Fax-Versand ausschließlich mit analogem Faxgerät; kein Software Fax2Mail</li>
                <li>Keine Upgrademöglichkeit auf Voll-Lizenz</li>
            </ul>
        </Stack.Item>
    </Stack>
</>


const defaultButtonStyleSet = mergeStyleSets({
    root: {
        borderRadius: "50%", height: 36, width: 36, minWidth: 0, padding: 0
    }
})

/*const toggleAllProperties = (attribute: "isAdmin" | "terminalserver" | "softphone" | "mwi", selection: Selection, userList: UserProps[], setUserList: React.Dispatch<React.SetStateAction<UserProps[]>>) => (e: any) => {
    const s = [...userList]
    const indicesToChange = selection.getSelectedIndices();
    for (const i of indicesToChange) {
        s[i][attribute] = !s[i][attribute];
    }
    setUserList(s);
}*/

const renderTextField = (area: "users" | "groups",
                         propertyName: "firstName" | "familyName" | "email" | "login" | "intPhoneNumber" | "extPhoneNumber" | "password" | "name",
                         //listToChange: UserProps[] | GroupProps[],
                         //usedPhoneNumbers: {intPhoneNumber: string|undefined, extPhoneNumber: string|undefined}[],
                         placeholder: string | undefined,
                         fieldValidator?: (value: string | undefined) => string | JSX.Element | undefined,
                         onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
                         onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string |undefined) => void,
                         onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
                         prefix?: string,
                         type?: string) =>
    (row: UserProps | GroupProps, index: number | undefined) => {
    return <TextField value={row[propertyName] || ""}
                      autoComplete={"off"}
                      prefix={prefix}
                      onFocus={onFocus}
                      type={type}
                      placeholder={placeholder}
                      canRevealPassword={propertyName === "password" ? true : undefined}
                      onKeyDownCapture={(e) => {
                          // Capture Tab
                         if (e.code === "Tab" && e.type === "keydown") {
                              e.preventDefault();
                              //e.stopPropagation();
                              return false;
                          }
                      }}
                      onChange={(e, text) => {
                          if (typeof index === "number") {
                              switch (area) {
                                  case "groups":
                                      const g = [...store.getState().setup.groups];
                                      g[index][propertyName] = text;
                                      ReduxDispatch.setGroup(index, g[index])
                                      break;
                                  case "users":
                                      const u = [...store.getState().setup.users];
                                      u[index][propertyName] = text;
                                      ReduxDispatch.setUser(index, u[index])
                                      break;
                              }
                          }
                          onChange && onChange(e, text);
                      }}
                      onBlur={(e) => {
                          onBlur && onBlur(e);
                          if (typeof index === "number") {
                              ReduxDispatch.validateAll()
                          }
                      }}
                      /*validateOnLoad={false}
                      validateOnFocusOut={true}
                      validateOnFocusIn={true}*/
                      errorMessage={fieldValidator && fieldValidator(row[propertyName])}

                      />
}


/*
Validation:
- unique logins
- at least one admin
- unique internal numbers
- unique external numbers
- non-empty names
 */

const loginRegExp = /^\d{2,}$/
const onValidateLogin = (users: UserProps[]) => (loginId: string | undefined) => {
    // fast checks first
    if (loginId === undefined || loginId.length < 2) {
        return "Login-ID darf nicht leer sein und muß aus mindestens zwei numerischen Ziffern bestehen";
    }

    const valid = loginId?.match(loginRegExp);
    if (!valid) {
        return "Login-ID darf nicht leer sein und muß aus mindestens zwei numerischen Ziffern bestehen";
    } else if (loginId && valid && multipleLoginIdAssignments(users, loginId)) {
        return "Die Login-ID ist bereits vergeben"
    }
    return undefined;
}
const onValidateName = (name: string | undefined) => {
    return name ? undefined : "Name darf nicht leer sein"
}
const onValidateEmail = (email: string | undefined) => {
    return isEmail(email) ? undefined : "Ungültige Emailadresse"
}
export const onValidatePassword = (password: string | undefined) => {
    return (password && password.length >= 8) ? undefined : "Kennwort muß mindestens 8 Zeichen lang sein"
}
const multipleLoginIdAssignments = (users: UserProps[], loginId: string) => {
    return users.filter((e) => { return e["login"] === loginId }).length > 1;
}
const multipleNumberAssignments = (groups: GroupProps[], users: UserProps[], number: string, attribute: "intPhoneNumber" | "extPhoneNumber") => {
    const groupOccurences = groups.filter((e) => { return e[attribute] === number }).length;
    const userOccurences = users.filter((e) => { return e[attribute] === number }).length;
    return (groupOccurences + userOccurences > 1);
}
const onValidateBaseNumber = (number: string) => {
    if (!number.startsWith("+")) {
        return "Die Stammrufnummer muß mit einem +-Zeichen beginnen"
    }
    if (number.startsWith("+00")) {
        return "Die Landesvorwahl ist ungültig"
    }
    if (number.length > 15) {
        return "Die Stammrufnummer ist zu lang"
    }
}
const onValidateLineConfig = memoizeFunction((type: "intPhoneNumber" | "extPhoneNumber", from: string, to: string) => {
    const fromNumber = Number(from);
    const toNumber = Number(to);
    const min = type === "intPhoneNumber" ? 10 : 0;
    const pattern = type === "intPhoneNumber" ? internalNumberPattern : externalNumberPattern;

    switch (type) {
        case "intPhoneNumber":
            if (isNaN(fromNumber) || isNaN(toNumber) || fromNumber < 0 || toNumber < 0) {
                return "Ungültige Rufnummer";
            }
            if (fromNumber < min) {
                return "Die Rufnummer muß größer oder gleich " + min + " sein"
            }
            if (toNumber < fromNumber) {
                return "Rufnummer muß größer oder gleich " + fromNumber + " sein"
            }
            if (!from.match(pattern) || !to.match(pattern)) {
                return "Ungültige Rufnummer"
            }
            break;
        case "extPhoneNumber":
            if ((to === undefined || to === "") && (("from") === undefined || from === "")) {
                // no external numbers
                return undefined;
            } else if (from === undefined || from === "") {
                // missing from-range
                return "Fehlender Beginn des Rufnummernbereichs"
            }

            if (!from.match(pattern) || !to.match(pattern)) {
                return "Ungültige Rufnummer"
            }

            if (isNaN(fromNumber) || isNaN(toNumber)) {
                return "Ungültige Rufnummer";
            }

            if ((!isNaN(fromNumber) && fromNumber < 0) || (!isNaN(toNumber) && toNumber < 0)) {
                return "Ungültige Rufnummer";
            }

            if (fromNumber < min) {
                return "Die Rufnummer muß größer oder gleich " + min + " sein"
            }
            if (toNumber < fromNumber) {
                return "Rufnummer muß größer oder gleich " + fromNumber + " sein"
            }
            break;
    }

    return undefined;
})
const onValidateNumber = (lineProps: LineProps, groupProps: GroupProps[], userProps: UserProps[], type: "intPhoneNumber" | "extPhoneNumber", rangeType: "from" | "to" | "other") => (number: string | undefined) => {
    const pn = Number(number);
    const from = type === "intPhoneNumber" ? lineProps.intFrom : lineProps.extFrom;
    const to = type === "intPhoneNumber" ? lineProps.intTo : lineProps.extTo;
    const pnFrom = Number(type === "intPhoneNumber" ? lineProps.intFrom : lineProps.extFrom);
    const pnTo = Number(type === "intPhoneNumber" ? lineProps.intTo : lineProps.extTo);
    const pattern = type === "intPhoneNumber" ? internalNumberPattern : externalNumberPattern;

    // internal numbers must be greater or equal to 10
    // external numbers must be greater or equal to 0
    const min = type === "intPhoneNumber" ? 10 : 0;

    if (type === "intPhoneNumber" && (pn === 110 || pn === 112)) {
        return number + " ist eine Notrufnummer"
    }

    if ((rangeType === from && pnFrom < 0) || (rangeType === "to" && pnTo < 0) || pn < 0) {
        //console.log("INVALID (0)");
        return "Ungültige Rufnummer"
    }

    // special case: external numbers may be undefined/empty
    if (type === "extPhoneNumber") {
        // validate ranges
        if ((lineProps.extFrom === "") && (lineProps.extTo === "") && (number === undefined || number === "")) {
            //console.log(rangeType + ": Valid external number in line configuration: empty | number: " + number);
            return undefined;
        }
        // empty numbers are valid regardless of a configured range
        if (number === undefined || number === "") {
            //console.log(rangeType + ": Valid external number in user/group configuration: empty | number:" + number);
            return undefined;
        }
    }

    // check a given number to be available in the valid(!) from-/to-range
    if (number !== undefined && rangeType === "other" && onValidateLineConfig(type, from, to) === undefined) {
        //console.log("number: " + number + " :" + number.match(pattern))
        if (!number.match(pattern)) {
            //console.log("INVALID (1)");
            return "Ungültige Rufnummer"
        }
        if (Number.isInteger(pn) && pn >= pnFrom && pn <= pnTo) {
            // check if number is already assigned and unavailable
            if (multipleNumberAssignments(groupProps, userProps, number, type)) {
                return "Die Rufnummer ist bereits vergeben"
            }
            //console.log(rangeType + ": Valid number: (1) | " + number);
            return undefined;
        } else {
            return "Die Rufnummer gehört nicht zum angegebenen Rufnummernbereich"
        }
    }

    // field value check (number is set) with invalid range configuration
    if (number !== undefined && rangeType === "other" && onValidateLineConfig(type, from, to)) {
        return "Ungültiger Rufnummernbereich"
    }

    // check from- or to-number to be valid in general
    if (from && rangeType === "from" && number === undefined) {
        if (pnFrom >= min) {
            //console.log(rangeType + ": Valid number: (2) | " + number);
            return undefined;
        } else {
            return "Die Rufnummer muß größer oder gleich " + min + " sein"
        }
    }

    if (to && rangeType === "to" && number === undefined) {
        if (!isNaN(pnFrom) && pnTo >= pnFrom && pnTo >= min) {
            //console.log(rangeType + ": Valid number: (3) | " + number);
            return undefined;
        } else {
            // the case where from is invalid
            if ((isNaN(pnFrom) || onValidateLineConfig(type, from, to)) && pnTo >= min) {
                return undefined;
            }
            if (from) {
                return "Rufnummer muß größer oder gleich " + (pnFrom >= min ? pnFrom : min) + " sein"
            } else {
                return "Rufnummer muß größer oder gleich " + min + " sein"
            }
        }
    }
    //console.log("INVALID (2)");
    return "Ungültige Rufnummer"
}
export const validateAllRows = (lineProps: LineProps, userState: UserProps[], groupState: GroupProps[]) => {
    console.log("ValidateAllRows")
    //const groupList = store.getState().setup.groups;
    //const userList = store.getState().setup.users;
    groupState.forEach((rowGroup, index) => {
        validateGroupRow(lineProps, rowGroup, groupState, userState);
    })
    userState.forEach((rowUser, index) => {
        validateUserRow(lineProps, rowUser, groupState, userState);
    })
}
const validateGroupRow = (lineProps: LineProps, row: GroupProps, groups: GroupProps[], users: UserProps[]) => {
    console.log("ValidateGroupRow")
    const prevValidity = row.isValid;
    if (row.name) {
        const isValid =
            !onValidateName(row.name) &&
            !onValidateNumber(lineProps, groups, users, "intPhoneNumber", "other")(row.intPhoneNumber) &&
            !onValidateNumber(lineProps, groups, users, "extPhoneNumber", "other")(row.extPhoneNumber);
        if (prevValidity !== isValid) {
            row.isValid = isValid;
            //ReduxDispatch.setGroupValid(index, isValid);
        }
    }
    return row;
}
const validateUserRow = (lineProps: LineProps, row: UserProps, groups: GroupProps[], users: UserProps[]) => {
    console.log("ValidateUserRows")
    // at least one value must be set
    const prevValidity = row.isValid;
    if (row.login || row.familyName || row.firstName || row.email || row.intPhoneNumber || row.extPhoneNumber) {
        const isValid =
            !onValidateLogin(users)(row.login) &&
            !onValidateName(row.familyName) &&
            !onValidateName(row.firstName) &&
            !onValidateEmail(row.email) &&
            !onValidatePassword(row.password) &&
            !onValidateNumber(lineProps, groups, users, "intPhoneNumber", "other")(row.intPhoneNumber) &&
            !onValidateNumber(lineProps, groups, users, "extPhoneNumber", "other")(row.extPhoneNumber);
        if (prevValidity !== isValid) {
            row.isValid = isValid;
        }
        //ReduxDispatch.setUserValid(index, isValid);
    }
    return row;
}

const internalNumberPattern = /^[1-9]\d+$/
const externalNumberPattern = /^\d+$/

const onBlurSuggestInternalNumber = (loginId: string, item: UserProps): UserProps => {
    if (!item.intPhoneNumber && loginId.match(internalNumberPattern)) {
        item.intPhoneNumber = loginId;
    }
    return item;
}

const onSave = (project: string) => {
    const data = exportToJSON(project);
    console.log(data);
    var blob = new Blob([data], {type: "application/json;charset=utf-8"})
    FileSaver.saveAs(blob, project + ".sfproject");
}
const set2Json = (key, value) => {
    if (typeof value === 'object' && value instanceof Set) {
        return [...value];
    }
    return value;
}
const exportToJSON = (project: string) => {
    const obj = {
        project: project,
        lineConfig: store.getState().setup.lineProperties,
        groups: store.getState().setup.groups,
        users: store.getState().setup.users,
        comment: store.getState().setup.comment
    };
    return JSON.stringify(obj, set2Json);
}

const importFromCSV = (csvStr: any) => {
    try {
        console.log("Read CSV");
        readString<SfCSVDate[]>(csvStr, {
            worker: true,
            header: true,
            skipEmptyLines: true,
            complete(results: ParseResult<SfCSVDate[]>) {
                const users = results.data.map(u => {
                    const extPhoneNumber = u["external"] || u["extern"] || u[3] || "";
                    return {
                        login: u["login"] || u[0],
                        firstName: u["firstname"] || u[1],
                        familyName: u["lastname"] || u[2],
                        email: u["mail"] || u[5],
                        password: u["password"] || u[6],
                        intPhoneNumber: u["internal"] || u["intern"] || u[4],
                        extPhoneNumber: getExtension(store.getState().setup.lineProperties.extBase, extPhoneNumber),
                        mwi: false,
                        license: (u["licensetype"]?.toLocaleLowerCase() === "light") ? "light" : "standard",
                        softphone: u["uci_perm"] || u[39],
                        terminalserver: u["winclient_terminal_server_perm"] || u[43],
                        isAdmin: u["admin_perm"] ||u[11]
                    } as UserProps;
                })
                ReduxDispatch.setUsers(users);
                ReduxDispatch.showImportSuccess(true);
            }
        });
    } catch (e) {console.error(e)}
}

export const stripPhoneNumberOfNonDigits = (v: string) => {
    const clean = v.replace(/[^\d]/g, '');
    return v.startsWith("+") ? "+" + clean : clean.replace(/^00/, "+");
}

export const formatParticipantNumber = (v: string) => {
    if (v && v?.length > 4) {
        v = normalizeIdp(v);
        try {
            let pn = parsePhoneNumber(v);
            return pn.formatInternational();
        } catch (e) {
            return v;
        }
    } else {
        return v;
    }
}
export const normalizeIdp = (v: string) => {
    if (v && v.startsWith("00")) {
        return "+" + v.substring(2);
    }
    return v;
}

const ringingStrategy = [
    {key: "ringall", text: "Gleichzeitiges Klingeln bei allen Mitgliedern"},
    {key: "hunt", text: "Nacheinander alle Mitglieder anklingeln"},
    {key: "iqueue", text: "Gruppe mit Warteschlange"},
    {key: "broadcast", text: "Durchsage (automatisches Annehmen)"},
]

export const boxStyleClass = mergeStyles({padding: "0px 20px 20px 20px", height: 300, minWidth: 250});
const defaultContentAreaStyleClass = mergeStyles({padding: "0px 20px 20px 20px"})