import * as React from "react";
import {useEffect, useState} from "react";
import {useUrlSearchParams} from "use-url-search-params";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardLogo,
    DocumentCardTitle,
    Image,
    ImageFit,
    Link,
    loadTheme,
    mergeStyles,
    PartialTheme,
    Spinner,
    Stack,
    ThemeProvider
} from "@fluentui/react";
import {paragraphStyleClass} from "../App";
import StyledDropzone from "react-dropzone";
import {boxStyleClass} from "./PcSetupUserForm";
import {ReduxDispatch} from "./ReduxStore";

export interface SfPartnerData {
    level?: string,
    company?: string,
    logo?: string,
    description?: string,
    address?: string,
    zip?: string,
    city?: string,
    country?: string,
    countryLong?: string,
    phone?: string,
    fax?: string,
    email?: string,
    link?: string,
    place_id?: string,
    location?: {
        lat?: string,
        lng?: string
    },
    queryAddress?: string
}

type Props = {
    theme: PartialTheme
}

/**
 * Formularabschnitt mit Kundenansprache/Willkommensnachricht
 * @param p
 * @constructor
 */
export const PcSfPartner: React.FC<Props> = (p: Props) => {
    const [partner, setPartner] = React.useState<SfPartnerData>();
    const [urlParams] = useUrlSearchParams({p: "", prj: ""}, {p: String, prj: String});

    const [files, setFiles] = React.useState<File[]>();
    const [importConfig, setImportConfig] = useState<boolean>(false);

    useEffect(() => {
        if (urlParams.p) {
            fetch("/partner.json", {...{method: "GET"}})
                .then(response => {
                    if (response.ok && response.status === 200) {
                        return response.json() as Promise<SfPartnerData[]>;
                    }
                })
                .then(partners => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    partners && setPartner(partners.find((p) => {
                        return p.place_id === urlParams.p
                    }));
                })
        }
    }, [urlParams.p])

    const theme = loadTheme(p.theme);

    return <ThemeProvider theme={theme}>
        <header style={{backgroundColor: "#ffffff", borderBottom: "1px solid " + theme.palette.themePrimary}}>
            <Stack horizontal tokens={{padding:50}} style={{position: "relative", overflow: "hidden"}}>
                <Stack.Item align={"center"}>
                    <div className={lineStyleClass} style={{backgroundColor: theme.palette.themePrimary}}></div>
                    <div style={{backgroundColor: "#ffffff", zIndex:1, position: "relative", padding: "10px 0 10px 15px", minWidth:270}}>
                        <span style={{fontSize: 32, fontWeight: 600, letterSpacing:1.5/*, color: "#f59c00"*/}}>STARFACE Installation</span><br/>
                        <span style={{fontSize: 24, fontWeight: 200}}>Vorbereitende Maßnahmen</span>
                    </div>
                </Stack.Item>
                <Stack.Item grow align={"center"}>
                    <h1>{urlParams.prj}</h1>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontalAlign={"center"}>
                        <Image src={"https://" + partner?.logo} width={250} height={100} imageFit={ImageFit.centerContain}></Image>
                        <div >{partner?.company}</div>
                        <div>{partner?.address}</div>
                        <div>{partner?.zip} {partner?.city}</div>
                    </Stack>
                </Stack.Item>
            </Stack>
        </header>
        <Stack horizontal>
            <Stack.Item>
                <div style={{margin: "60px 5px 60px 30px"}}>
                    <p className={paragraphStyleClass}>
                        Liebe Kunden,<br/>
                        sehr geehrte Damen und Herren,
                    </p>
                    <p className={paragraphStyleClass}>
                        wir freuen uns, dass Sie uns Ihr Vertrauen schenken.
                        Als Vorbereitung für die Installation Ihrer STARFACE, bitten wir Sie, uns ein paar notwendige Informationen bereitzustellen.
                        Füllen Sie hierzu die folgenden Formulare auf dieser Seite aus. Sie können Ihre Eingaben speichern und herunterladen und die Bearbeitung auch später fortsetzen.
                        Wir werden anschließend alles für eine schnelle Bereitstellung bzw. Inbetriebnahme Ihrer neuen STARFACE tun.
                    </p>
                    <p className={paragraphStyleClass}>
                        Sollten Sie Fragen haben, können Sie uns gerne telefonisch unter <Link href={"tel://" + partner?.phone}>{partner?.phone}</Link> oder per Email an <Link href={"mailto:" + partner?.email}>{partner?.email}</Link> kontaktieren.
                    </p>
                    <p className={paragraphStyleClass}>
                        Mit den besten Grüßen,<br/>
                        Ihr Team von {partner?.company}
                    </p>
                </div>
            </Stack.Item>
            <Stack.Item>
                <DocumentCard className={boxStyleClass} style={{margin: "60px 30px 30px 5px"}}>
                    <DocumentCardLogo logoIcon={"ConfigurationSolid"} />
                    <DocumentCardTitle title={"Bestehende Konfigurationsdatei"}/>
                    <DocumentCardDetails>
                        <div style={{padding: "5px"}}>
                        Falls Sie bereits eine Konfiguration angelegt haben, können Sie diese laden und die Bearbeitung fortführen.
                        </div>
                        <StyledDropzone maxFiles={1}
                                        onDrop={(acceptedFiles, rejectedFiles, e) => {
                                            setImportConfig(true);
                                            e.preventDefault();
                                            setFiles(acceptedFiles);
                                            if (acceptedFiles[0]) {
                                                acceptedFiles[0].text().then(text => {
                                                    importFromJSON(text);
                                                    setImportConfig(false);
                                                });
                                            }
                                        }}>
                            {({getRootProps, getInputProps, isDragActive, isDragAccept}) => (
                                // Dropzone
                                <div className={"dropzone"} {...getRootProps()}>
                                    <input {...getInputProps()}/>
                                    {isDragActive && isDragAccept ?
                                        <div className={"dropzone-active"}></div>
                                        :
                                        <Stack verticalAlign={"center"} verticalFill>Datei hier ablegen oder<br/><i>klicken</i> um Datei auszuwählen.</Stack>
                                    }
                                </div>
                            )}
                        </StyledDropzone>
                        <br/>
                        {importConfig && <Spinner label={"Importiere Konfigurationsdatei..."}/>}
                    </DocumentCardDetails>
                </DocumentCard>
            </Stack.Item>
        </Stack>
        <hr/>
    </ThemeProvider>
}

const importFromJSON = (data: any) => {
    try {
        const d = JSON.parse(data);
        // todo: validate using JSON Schema
        ReduxDispatch.setLines(d.lineConfig);
        ReduxDispatch.setGroups(d.groups);
        ReduxDispatch.setUsers(d.users);
        ReduxDispatch.setComment(d.comment);

        // Tell user all went well
        ReduxDispatch.showImportSuccess(true);
    } catch (e) {return null;}
}

const lineStyleClass = mergeStyles({
    position: "absolute",
    height: 300,
    width: 512,
    left: -184,
    top: -45,
    transform: "rotate(308deg)"
});