const initialGroupProps: GroupProps[] = [{
    id: "initialProp",
    isValid: false,
    name: "Zentrale",
    intPhoneNumber: "99",
    extPhoneNumber: "0",
    strategy: "ringall"
}];
const initialUserProps: UserProps[] = [{
    isValid: false,
    login: "10",
    email: "",
    password: "",
    intPhoneNumber: "10",
    faxSender: "",
    firstName: "",
    familyName: "",
    mwi: false,
    license: "standard",
    softphone: false,
    terminalserver: false,
    isAdmin: true,
    language: "de"
}];
const initialLineProperties: LineProps = {
    intFrom: "10", intTo: "99",
    extLV: "49",
    extOV: "7121",
    extBase: "12345",
    extFrom: "0", extTo: "999",
    extFormattedNumber: "+49 7121 12345"
}

export class SetupState {

    public lineProperties: LineProps = initialLineProperties;
    public groups: GroupProps[] = initialGroupProps;
    public users: UserProps[] = initialUserProps;

    public commonDefaults: CommonProps = {
        password: undefined, faxSender: undefined, forwardingTarget: undefined
    };

    public comment: string = "";

    public static empty = () => new SetupState();
}


export interface GroupProps {
    id: string,
    isValid?: boolean,
    name: string,
    intPhoneNumber: string
    extPhoneNumber: string,
    strategy: "ringall" | "hunt" | "iqueue" | "broadcast"
}
export interface UserProps {
    isValid?: boolean,
    login?: string,
    familyName?: string,
    firstName?: string,
    email?: string,
    password?: string,
    intPhoneNumber?: string,
    extPhoneNumber?: string,
    mwi?: boolean,
    faxSender?: string,
    license?: "light" | "standard",
    softphone?: boolean,
    terminalserver?: boolean,
    isAdmin?: boolean,
    groupMembership?: Set<string>,
    language: "de"
}

export type LineProps = {
    intFrom: string,
    intTo: string,
    extLV: string,
    extOV: string
    extBase: string,
    extFrom: string,
    extTo: string,
    extFormattedNumber: string
}

export interface CommonProps {
    password?: string,
    faxSender?: string,
    forwardingTarget?: string
}

export interface AddOnProps {

}
