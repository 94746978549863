import * as React from "react";
import {useMsal} from "@azure/msal-react";
import {IPublicClientApplication} from "@azure/msal-browser";
import {PrimaryButton} from "@fluentui/react";
import {loginRequest} from "../config";

const handleLogin = (instance: IPublicClientApplication) => {
    return instance.loginPopup(loginRequest)
        .catch(e => {
            console.error(e);
        });
}

type Props = {
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
    disabled?: boolean
}

export const SignInButton: React.FC<Props> = (p: Props) => {
    const { instance, accounts, inProgress } = useMsal();
    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    return <PrimaryButton disabled={p.disabled} primary onClick={
        () => {
            handleLogin(instance)
                .then(() => {
                    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                    instance.acquireTokenSilent(request).then((response) => {
                        p.setAccessToken(response.accessToken);
                    }).catch((e) => {
                        instance.acquireTokenPopup(request).then((response) => {
                            p.setAccessToken(response.accessToken);
                        });
                    });
                });
        }
    } iconProps={{iconName: "Signin"}}>Anmelden</PrimaryButton>
}