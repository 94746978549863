import * as React from "react";
import {useMsal} from "@azure/msal-react";
import {IPublicClientApplication} from "@azure/msal-browser";
import {DefaultButton} from "@fluentui/react";
import {loginRequest} from "../config";

const handleLogout = (instance: IPublicClientApplication) => {
    return instance.logoutPopup();
}

type Props = {
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
    disabled?: boolean
}

export const SignOutButton: React.FC<Props> = (p: Props) => {
    const { instance, accounts, inProgress } = useMsal();
    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    return <DefaultButton disabled={p.disabled} primary onClick={
        () => {
            handleLogout(instance)
                .then(() => {
                    p.setAccessToken(null);
                });
        }
    } iconProps={{iconName: "Door"}}>Abmelden</DefaultButton>
}