// eslint-disable-next-line no-control-regex
export const emailRegexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// eslint-disable-next-line no-control-regex
export const companyRegExp = /(\s?KG$)|(\se\.K\.$)|(\sGmbH)|(Co\.?)|(\sAG$)|(\smbH$)|\s|[-&+.–]|(\s)/gi;
export const isEmail = (s: string | undefined) => {
    return s?.match(emailRegexp);
}

export const emailDomain = (email: string) => email.substr(email.indexOf("@") + 1).toLowerCase();
export const emailUser = (email: string) => email.substr(0, email.indexOf("@")).toLowerCase();
export const extPrefix = (basenumber: string) => basenumber?.replace(/[^0-9]/g,''); // removes non-digit numbers => 4912345
export const getExtension = (baseNumber: string, phoneNumber: string) => extPrefix(phoneNumber)?.replace(extPrefix(baseNumber), '')

export interface SfCSVDate {
    login?: string,
    firstname?: string,
    lastname?: string,
    external?: string,
    internal?: string,
    mail?: string,
    password?: string,
    licensetype?: "User" | "Light",
    address_perm?: boolean,
    admin_actioncodes_perm?: boolean,
    admin_mail_perm?: boolean,
    admin_perm?: boolean,
    admin_groups_perm?: boolean,
    admin_misc_perm?: boolean,
    admin_modules_perm?: boolean,
    admin_phones_perm?: boolean,
    admin_user_perm?: boolean,
    call_recording_perm?: boolean,
    calllist_perm?: boolean,
    callman_perm?: boolean,
    conference_perm?: boolean,
    fax_perm?: boolean,
    fkey_perm?: boolean,
    fkey_module_key?: boolean,
    fkey_provide_no_call_detail_perm?: boolean,
    fkey_show_act_call_perm?: boolean,
    fkey_show_all_call_detail_perm?: boolean,
    fkey_show_inc_call_perm?: boolean,
    fkey_shw_fwd_state_perm?: boolean,
    fkey_shw_im_state_perm?: boolean,
    ifmc_use_perm?: boolean,
    ifmc_edit_perm?: boolean,
    im_perm?: boolean,
    login_perm?: boolean,
    phone_login_perm?: boolean,
    redirect_perm?: boolean,
    group_redirect_perm?: boolean,
    remote_perm?: boolean,
    setting_perm?: boolean,
    uci_perm?: boolean,
    neon_video_meetings_perm?: boolean,
    uci_autoprovisioning_perm?: boolean,
    ms_teams_integration_perm?: boolean,
    winclient_terminal_server_perm?: boolean,
    voice_perm?: boolean
}