import * as React from "react";
import {Dialog, DialogFooter, DialogType, PrimaryButton} from "@fluentui/react";
import {ReduxDispatch} from "./ReduxStore";
import {SetupState} from "./SetupState";

type Props = {
    setup: SetupState
    hidden?: boolean
}

export const PcImportDialog: React.FC<Props> = (p: Props) => {
    return <Dialog
        hidden={p.hidden}
        onDismiss={() => ReduxDispatch.showImportSuccess(false)}
        dialogContentProps={importDialogContentProps}
        modalProps={{isBlocking: true}}>
        <p>
            {p.setup.groups.length}&nbsp;Gruppe(n)<br/>
            {p.setup.users.length}&nbsp;Benutzer<br/>
        </p>
        <DialogFooter>
            <PrimaryButton onClick={() => ReduxDispatch.showImportSuccess(false)} text={"Ok"} />
        </DialogFooter>
    </Dialog>
}

const importDialogContentProps = {
    type: DialogType.normal,
    title: 'Import erfolgreich',
    subText: 'Es wurden die folgenden Daten importiert:'
}