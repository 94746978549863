import * as React from "react";
import {useEffect} from "react";
import {
    ColorPicker,
    Dialog,
    DialogType,
    DocumentCard,
    DocumentCardDetails,
    DocumentCardLogo,
    DocumentCardTitle,
    Dropdown,
    getColorFromString,
    IColor,
    IDropdownOption,
    Image,
    ImageFit,
    Label,
    Link,
    Separator,
    Stack,
    TextField,
    Toggle
} from "@fluentui/react";
import {ReduxDispatch} from "./ReduxStore";
import {SfPartnerData} from "./PcSfPartner";
import {useBoolean} from "@fluentui/react-hooks";
import {companyRegExp} from "../Utils";
import {boxStyleClass} from "./PcSetupUserForm";
import StyledDropzone from "react-dropzone";
import {SetupState} from "./SetupState";

type Props = {
    setup: SetupState
    hidden?: boolean
}

/**
 * Dialogfenster zum Erstellen eines SetupLink
 * @param p
 * @constructor
 */
export const PcPartnerDialog: React.FC<Props> = (p: Props) => {
    const [sfPartnerData, setSfPartnerData] = React.useState<SfPartnerData[] | undefined>(undefined);
    const [partnerObjects, setPartnerObjects] = React.useState<SfPartnerData[]>();

    const [placeOptions, setPlaceOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedPlace, setSelectedPlace] = React.useState<SfPartnerData>();

    const [partner, setPartner] = React.useState<string>(""); // Partner name as entered by user
    const [project, setProject] = React.useState<string>(""); // Project name as entered by user

    const [customColor, {toggle: toggleCustomColor}] = useBoolean(false);
    const [color, setcolor] = React.useState<IColor>(getColorFromString("#f59c00")!); // Project name as entered by user

    useEffect(() => {
        if (!sfPartnerData) {
            fetch("/partner.json", {...{method: "GET"}})
                .then(response => {
                    if (response.ok && response.status === 200) {
                        return response.json() as Promise<SfPartnerData[]>;
                    }
                })
                .then(json => {
                    setSfPartnerData(json);
                })
        }
    })

    useEffect(() => {
        if (sfPartnerData) {
            // todo: strip whitespaces and special Characters?!
            const pObjects = sfPartnerData.filter((p) => { return equalCompany(p.company, partner) })
            setPartnerObjects(pObjects);
            if (pObjects.length > 1) {
                const options = pObjects.map((o, index) => {
                    return {
                        index: index,
                        key: o.place_id,
                        text: o.zip + " " + o.city + ", " + o.address,
                        data: o
                    } as IDropdownOption
                });
                setPlaceOptions(options);
            } else if (pObjects.length === 1) {
                // select place
                setSelectedPlace(pObjects[0]);
            }
        }
    }, [sfPartnerData, partner])

    let c = "";
    if (customColor && (color.r !== 245 || color.g !== 156 || color.b !== 0)) {
        // non-default
        c = "&c=" + color.hex;
    }
    const link = "https://setup.sf-app.de/?p=" + selectedPlace?.place_id + "&prj=" + encodeURIComponent(project) + c;

    return <Dialog onDismiss={() => ReduxDispatch.showPartnerDialog(false)}
                   dialogContentProps={dialogContentProps}
                   hidden={p.hidden}
                   maxWidth={850}
                   modalProps={{isBlocking: false}}>
        <Stack horizontal tokens={{childrenGap: 40}} verticalAlign={"center"}>
            <Stack.Item>
                <Stack tokens={{childrenGap: 10}}>
                    {partnerObjects && partnerObjects?.length > 0 && <Stack.Item>
                        <Image src={"https://" + partnerObjects[0]?.logo} width={400} height={100} imageFit={ImageFit.centerContain}></Image>
                    </Stack.Item>}
                    <Stack.Item>
                        <Label required>Partner Firmenname:</Label>
                        <TextField underlined value={partner} onChange={
                            (e, text) => {
                                setPartner(text || "");
                            }
                        } autoCorrect={"off"} onGetErrorMessage={(name) => {
                            if (name.length >= 2 && (!partnerObjects || partnerObjects?.length < 1)) {
                                return "Dieser Firmenname ist leider nicht in unserer Datenbank."
                            }
                            return undefined;
                        }}/>
                    </Stack.Item>
                    {partnerObjects && partnerObjects.length > 1 && <Stack.Item>
                        <Label required>Standort:</Label>
                        <Dropdown options={placeOptions} selectedKey={selectedPlace?.place_id}
                                  onChange={(e, o) => {
                                      o && setSelectedPlace(o.data);
                                  }}/>
                    </Stack.Item>}
                    <Stack.Item>
                        <Label>Projektname:</Label>
                        <TextField underlined value={project} onChange={
                            (e, text) => {
                                setProject(text || "");
                            }
                        }/>
                    </Stack.Item>
                    <Stack.Item>
                        <br/>
                        <Toggle onClick={toggleCustomColor} label={"Eigene Corporate Farbe"} inlineLabel={true} />
                        {customColor &&
                            <ColorPicker color={color} alphaType={"none"} onChange={(e, color) => {
                                setcolor(color);
                            }}/>}
                    </Stack.Item>
                    <Stack.Item>
                        <br/>
                        <Separator/>
                    </Stack.Item>
                    {selectedPlace?.place_id && <Stack.Item>
                        <Label>SetupLink:</Label>
                        <Link href={link} target={"_blank"}>{link}</Link>
                        <p>Senden Sie den SetupLink an Ihren Kunden, um diesem ein Installationsformular mit Ihren Partnerdaten zur Verfügung zu stellen.</p>
                    </Stack.Item>}
                    <div style={{fontSize:"smaller"}}>
                        <p>Hinweis: Es werden keine Kundendaten gespeichert oder übermittelt. Die Installationsvorbereitung läuft ausschließlich innerhalb des Webbrowsers beim Kunden.</p>
                    </div>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <i>oder</i>
            </Stack.Item>
            <Stack.Item>
                <DocumentCard className={boxStyleClass}>
                    <DocumentCardLogo logoIcon={"ConfigurationSolid"} />
                    <DocumentCardTitle title={"Konfiguration laden"}/>
                    <DocumentCardDetails>
                        <div style={{padding: "5px"}}>
                            Vom Kunden erstellte EasySetup-Konfigurationsdatei laden.
                        </div>
                        <StyledDropzone maxFiles={1}
                                        onDrop={(acceptedFiles, rejectedFiles, e) => {
                                            //setImportConfig(true);
                                            e.preventDefault();
                                            //setFiles(acceptedFiles);
                                            if (acceptedFiles[0]) {
                                                acceptedFiles[0].text().then(text => {
                                                    //importFromJSON(text);
                                                    //setImportConfig(false);
                                                });
                                            }
                                        }}>
                            {({getRootProps, getInputProps, isDragActive, isDragAccept}) => (
                                // Dropzone
                                <div className={"dropzone"} {...getRootProps()}>
                                    <input {...getInputProps()}/>
                                    {isDragActive && isDragAccept ?
                                        <div className={"dropzone-active"}></div>
                                        :
                                        <Stack verticalAlign={"center"} verticalFill>Datei hier ablegen oder<br/><i>klicken</i> um Datei auszuwählen.</Stack>
                                    }
                                </div>
                            )}
                        </StyledDropzone>
                    </DocumentCardDetails>
                </DocumentCard>
            </Stack.Item>
        </Stack>
    </Dialog>
}


const equalCompany = (a: string | undefined, b: string | undefined) => {
    if (a === undefined || b === undefined) {
        return false;
    }
    //console.log(a + " : " + a.replace(companyRegExp,''));
    return a.trim().replace(companyRegExp,'').toLocaleLowerCase() === b.trim().replace(companyRegExp,'').toLocaleLowerCase();
}

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'SetupLink für Kunden-Installation erstellen',
    subText: 'Geben Sie die folgenden Informationen ein, um für Ihre Kunden einen SetupLink zu erstellen, der eine STARFACE-Installation vorbereitet – oder importieren Sie eine vom Kunden erstellte Konfigurationsdatei.'
}

//<p>Geben Sie Ihren Firmennamen so ein, wie er im STARFACE Partner-Finder angegeben ist. Bei einer Übereinstimmung, wird Ihr Firmenlogo eingeblendet.</p>