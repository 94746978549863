import * as React from "react";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Label,
    PrimaryButton,
    ProgressIndicator,
    Separator,
    Stack,
    TextField
} from "@fluentui/react";
import {SetupState} from "./SetupState";
import {ReduxDispatch} from "./ReduxStore";

type Props = {
    setup: SetupState
    hidden?: boolean
}

export const PcUtpExportDialog: React.FC<Props> = (p: Props) => {

    const [hostname, setHostname] = React.useState<string>("");
    const [moduleName, setModuleName] = React.useState<string>("");
    const [username, setUsername] = React.useState<string>("0001");
    const [password, setPassword] = React.useState<string>("");

    const [progress, setProgress] = React.useState<number>(0);
    const [progressDescription, setProgressDescription] = React.useState<string>("");

    return <Dialog onDismiss={() => {ReduxDispatch.showExportUtpDialog(false)}}
                   dialogContentProps={dialogContentProps}
                   hidden={p.hidden}
                   maxWidth={600}
                   modalProps={{isBlocking: true}}
    >
        <Stack tokens={{childrenGap: 10}}>
            <Stack.Item>
                <Label required>STARFACE Hostname/IP-Adresse:</Label>
                <TextField underlined value={hostname} onChange={
                    (e, text) => {
                        setHostname(text || "");
                    }
                } prefix={"https://"}/>
            </Stack.Item>
            <Stack.Item>
                <Label required>Modulkonfiguration:</Label>
                <TextField underlined value={moduleName} onChange={
                    (e, text) => {
                        setModuleName(text || "");
                    }
                } placeholder={"Name der User Template PRO Modulkonfiguration"}/>
            </Stack.Item>
            <Stack.Item>
                <Label required>STARFACE Admin Login-ID:</Label>
                <TextField underlined value={username} onChange={
                    (e, text) => {
                        setUsername(text || "");
                    }
                } placeholder={"Administrator Login-ID"}/>
            </Stack.Item>
            <Stack.Item>
                <Label required>STARFACE Kennwort:</Label>
                <TextField underlined value={password} type={"password"} canRevealPassword onChange={
                    (e, text) => {
                        setPassword(text || "");
                    }
                } placeholder={"Administrator Kennwort"}/>
            </Stack.Item>
            <Stack.Item>
                <br/>
                <Separator/>
                <ProgressIndicator label="STARFACE Konfiguration" description={progressDescription} progressHidden={true} />
                <Separator/>
            </Stack.Item>
            <div style={{fontSize:"smaller"}}>
                <p>Hinweis: User Template PRO muß als aktives Modul ausgeführt werden und für die Zielanlage lizenziert sein. Die vorliegende Konfiguration wird zusätzlich zu einer bereits bestehenden Konfiguration angewendet. Bereits vorhandene Benutzer werden <i>nicht</i> gelöscht und können zu Importkonflikten führen.</p><p>Eine Übertragung ist ausschließlich über eine gesicherte HTTPS-Verbindung möglich. Steht HTTPS nicht zur Verfügung, muß die Konfigurationsdatei exportiert und in die Modulkonfiguration von User Template PRO hochgeladen werden.</p>
            </div>
        </Stack>
        <DialogFooter>
            <PrimaryButton onClick={() => exportConfig(hostname, moduleName, username, password, p.setup)} text={"Übertragung starten"} />
            <DefaultButton onClick={() => ReduxDispatch.showExportUtpDialog(false)} text={"Abbrechen"} />
        </DialogFooter>
    </Dialog>
}

const dialogContentProps = {
    type: DialogType.largeHeader,
    title: 'Konfiguration übertragen und anwenden',
    subText: 'Geben Sie die folgenden Informationen ein, um die aktuelle Konfiguration an User Template PRO zu übertragen und auf eine STARFACE-Installation anzuwenden.'
}

function sha512(str) {
    return crypto.subtle.digest("SHA-512", new TextEncoder().encode(str)).then(buf => {
        return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
}

const exportConfig = async (host: string, moduleName: string, user: string, password: string, setup: SetupState) => {
    const pwHash = await sha512(password);
    const saltedHash = await sha512(user + "*" + pwHash)
    const auth = "de.vertico.starface.auth=" + user + ":" + saltedHash;

    var xml_input = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
        "<methodCall>" +
        "<methodName>" + moduleName + ".easySetup</methodName>" +
        "<params>" +
            "<param>" +
                "<value>" +
                    "<struct>" +
                        "<member>" +
                            "<name>user</name>" +
                            "<value><string>" + user + "</string></value>" +
                        "</member>" +
                        "<member>" +
                            "<name>pass</name>" +
                            "<value><string>" + password + "</string></value>" +
                        "</member>" +
                        "<member>" +
                            "<name>setup</name>" +
                            "<value><string>" + JSON.stringify(setup) + "</string></value>" +
                        "</member>" +
                    "</struct>" +
                "</value>" +
            "</param>" +
        "</params>" +
        "</methodCall>";

    /*const httpsAgent = new https.Agent({
        rejectUnauthorized: false,
    });
    const options = {
        hostname: host,
        port: 443,
        path: "/xml-rpc?" + auth,
        method: 'POST',
        rejectUnauthorized: false,
        headers: {
            "Content-Type": "text/plain",
            "Access-Control-Allow-Origin": "*"
        }
    } as https.RequestOptions;
    options.agent = httpsAgent;*/

    return new Promise((resolve, reject) => {

        /*const req = https.request(options, (res) => {
            console.log('statusCode:', res.statusCode);
            console.log('headers:', res.headers);
            res.on('data', (d) => {
                process.stdout.write(xml_input);
            });
        });

        req.on('error', (e) => {
            console.error(e);
        });
        req.end();*/

        fetch("https://" + host + "/xml-rpc?" + auth, {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "text/plain"
            },
            body: xml_input
        })
            .then(response => {
                if (response.status !== 200) {
                    console.log(response)
                } else {
                    resolve(response);
                }
            })
            .catch(reason => reject(reason))
    }).catch(r => console.log(r));
}