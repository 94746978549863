import {combineReducers} from "redux";
import {GroupProps, LineProps, SetupState, UserProps} from "./SetupState";
import {Selection} from "@fluentui/react";
import {onValidatePassword, validateAllRows} from "./PcSetupUserForm";
import {generateUUID} from "@microsoft/microsoft-graph-client/lib/src/middleware/MiddlewareUtil";
import {ReduxDispatch} from "./ReduxStore";
import {UiState} from "./UiState";

export const SET_LINE_PROPERTIES = "SET_LINE_PROPERTIES";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUPS = "SET_GROUPS";
export const DELETE_GROUPS = "DELETE_GROUPS";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_USER_VALIDITY = "SET_USER_VALIDITY";
export const SET_GROUP_VALIDITY = "SET_GROUP_VALIDITY";
export const DELETE_USERS = "DELETE_USERS";
export const ADD_USER = "ADD_USER";
export const ADD_GROUP = "ADD_GROUP";
export const TOGGLE_USER_SETTING = "TOGGLE_USER_SETTING";
export const SET_COMMON_PASSWORD = "SET_COMMON_PASSWORD";
export const SET_COMMON_FAXSENDER = "SET_COMMON_FAXSENDER";
export const SET_COMMON_FORWARDING_TARGET = "SET_COMMON_FORWARDING_TARGET";
export const SET_COMMENT = "SET_COMMENT";
export const VALIDATE_ALL = "VALIDATE_ALL";

export const SHOW_IMPORT_SUCCESS_DIALOG = "SHOW_IMPORT_SUCCESS_DIALOG";
export const SHOW_PARTNER_DIALOG = "SHOW_PARTNER_DIALOG";
export const SHOW_EXPORT_UTP_DIALOG = "SHOW_EXPORT_UTP_DIALOG";

interface SetLineProperties {
    type: typeof SET_LINE_PROPERTIES
    payload: LineProps
}
interface SetGroup {
    type: typeof SET_GROUP
    payload: GroupProps
    index: number
}
interface SetGroups {
    type: typeof SET_GROUPS
    payload: GroupProps[]
}
interface DeleteGroups {
    type: typeof DELETE_GROUPS
    selection: Selection
}
interface SetUser {
    type: typeof SET_USER
    payload: UserProps,
    index: number
}
interface SetUsers {
    type: typeof SET_USERS
    payload: UserProps[],
}
interface SetUserValidity {
    type: typeof SET_USER_VALIDITY
    validity: boolean,
    index: number
}
interface SetGroupValidity {
    type: typeof SET_GROUP_VALIDITY
    validity: boolean,
    index: number
}
interface DeleteUsers {
    type: typeof DELETE_USERS
    selection: Selection
}
interface AddGroup {
    type: typeof ADD_GROUP
}
interface AddUser {
    type: typeof ADD_USER
}
interface ToggleUserSetting {
    type: typeof TOGGLE_USER_SETTING
    selection: Selection
    attribute: "mwi" | "softphone" | "terminalserver" | "isAdmin"
}
interface SetCommonPassword {
    type: typeof SET_COMMON_PASSWORD
    password: string
}
interface SetCommonFaxSender {
    type: typeof SET_COMMON_FAXSENDER
    sender: string
}
interface SetCommonForwardingTarget {
    type: typeof SET_COMMON_FORWARDING_TARGET
    target: string
}
interface SetComment {
    type: typeof SET_COMMENT
    comment: string
}
interface ValidateAll {
    type: typeof VALIDATE_ALL
}

interface ShowImportSuccessDialog {
    type: typeof SHOW_IMPORT_SUCCESS_DIALOG
    show: boolean
}
interface ShowPartnerDialog {
    type: typeof SHOW_PARTNER_DIALOG
    show: boolean
}
interface ShowExportUtpDialog {
    type: typeof SHOW_EXPORT_UTP_DIALOG
    show: boolean
}

export type SetupActionTypes = SetLineProperties |
    SetGroup |
    SetGroups |
    DeleteGroups |
    SetUser |
    SetUsers |
    DeleteUsers |
    AddGroup |
    AddUser |
    ToggleUserSetting |
    SetUserValidity |
    SetGroupValidity |
    SetCommonFaxSender |
    SetCommonForwardingTarget |
    SetCommonPassword |
    SetComment |
    ValidateAll;

export type UiActionTypes = ShowImportSuccessDialog |
    ShowPartnerDialog |
    ShowExportUtpDialog;

//////////////////////////////////////////////////

const initialSetupState: () => SetupState = () => {
    return SetupState.empty();
}
const initialUiState: () => UiState = () => {
    return UiState.empty();
}

function uiReducer(prevState = initialUiState(), action: UiActionTypes) {
    const nextState: UiState = {...prevState};

    switch (action.type) {
        case SHOW_IMPORT_SUCCESS_DIALOG:
            nextState.importDialogVisible = action.show;
            break;
        case SHOW_PARTNER_DIALOG:
            nextState.partnerDialogVisible = action.show;
            break;
        case SHOW_EXPORT_UTP_DIALOG:
            nextState.exportUtpDialogVisible = action.show;
            break;
    }
    return nextState;
}

function setupReducer(prevState = initialSetupState(), action: SetupActionTypes) {
    const nextState: SetupState = {...prevState};

    switch (action.type) {
        case SET_LINE_PROPERTIES:
            nextState.lineProperties = action.payload;
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            // validate
            break;
        case SET_GROUP:
            nextState.groups[action.index] = action.payload;
            // validate
            break;
        case SET_GROUPS:
            nextState.groups = action.payload;
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
        case SET_USER:
            nextState.users[action.index] = action.payload;
            break;
        case SET_USERS:
            console.log(JSON.stringify(action.payload))
            nextState.users = action.payload;
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
        case DELETE_GROUPS:
            // we need to sort the list in descending order to delete the row indices from last to first or else the indices will change during alteration
            const groupIndicesToDelete = action.selection.getSelectedIndices().sort((a, b) => {
                return b - a
            });
            for (const i of groupIndicesToDelete) {
                const removedElement = nextState.groups.splice(i, 1);
                if (removedElement.length === 1) {
                    // remove the group from user assignments
                    const id2remove = removedElement[0].id;
                    nextState.users.forEach((u, index) => {
                        if (u.groupMembership?.has(id2remove)) {
                            u.groupMembership?.delete(id2remove);
                            ReduxDispatch.setUser(index, u);
                        }
                    })
                }
            }
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
        case DELETE_USERS:
            const userIndicesToDelete = action.selection.getSelectedIndices().sort((a, b) => {
                return b - a
            });
            for (const i of userIndicesToDelete) {
                nextState.users.splice(i, 1);
            }
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
        case ADD_GROUP:
            nextState.groups.push({strategy: "ringall", intPhoneNumber: "", extPhoneNumber: "", name: "", id: generateUUID()})
            break;
        case ADD_USER:
            nextState.users.push({
                isValid: false,
                password: nextState.commonDefaults.password,
                faxSender: nextState.commonDefaults.faxSender,
                license: "standard",
                language: "de"}
            )
            break;
        case TOGGLE_USER_SETTING:
            const indicesToChange = action.selection.getSelectedIndices();
            for (const i of indicesToChange) {
                nextState.users[i][action.attribute] = !nextState.users[i][action.attribute];
            }
            break;
        case SET_USER_VALIDITY:
            nextState.users[action.index].isValid = action.validity;
            break;
        case SET_GROUP_VALIDITY:
            nextState.groups[action.index].isValid = action.validity;
            break;
        case SET_COMMON_FAXSENDER:
            nextState.commonDefaults.faxSender = action.sender;
            nextState.users.forEach((u, index) => {
                if (!u.faxSender) {
                    nextState.users[index].faxSender = action.sender;
                }
            })

            break;
        case SET_COMMON_FORWARDING_TARGET:
            nextState.commonDefaults.forwardingTarget = action.target;
            break;
        case SET_COMMON_PASSWORD:
            if (onValidatePassword(action.password) === undefined) {
                nextState.commonDefaults.password = action.password;
                nextState.users.forEach((u, index) => {
                    if (!u.password) {
                        nextState.users[index].password = action.password;
                    }
                })
            }
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
        case SET_COMMENT:
            nextState.comment = action.comment;
            break;
        case VALIDATE_ALL:
            validateAllRows(nextState.lineProperties, nextState.users, nextState.groups);
            break;
    }
    return nextState;
}


const rootReducer = combineReducers({
    setup: setupReducer,
    ui: uiReducer
})
export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;