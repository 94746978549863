import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {CcApp} from './App.tsx';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {initializeIcons} from "@fluentui/font-icons-mdl2";
import {msalConfig} from "./config";
import {store} from "./components/ReduxStore";
import {Provider} from "react-redux";

initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <CcApp />
          </Provider>
      </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
