import * as React from "react";
import {useMsal} from "@azure/msal-react";
import {CompoundButton} from "@fluentui/react";
import {loginRequest} from "../config";
import {User} from '@microsoft/microsoft-graph-types';
import {CommonProps, UserProps} from "./SetupState";
import {ReduxDispatch, store} from "./ReduxStore";
import {getExtension} from "../Utils";

const importUsers = (p: Props) => {
    if (p.accessToken) {
        const azureTokens: RequestInit = {
            headers: {
                Authorization: "Bearer " + p.accessToken
            }
        }
        new Promise((resolve, reject = (reason) => {
        }) => {
            fetch("https://graph.microsoft.com/v1.0/users?$select=surname,givenname,mail,accountEnabled,usertype,businessPhones,photo", {...{method: "GET"}, ...azureTokens})
                //.then(handle4xxResponses)
                .then(response => handleResponse(response, "json"))
                .then(json => {
                    json && (resolve(json.value))
                })
                .catch(reason => reject(reason));
        }).then(result => {
            const r = (result as User[]);
            ReduxDispatch.setUsers(r.filter((e) => {
                // select only users that have the following fields set
                return e.accountEnabled && e.userType === "Member" && e.mail && e.surname && e.givenName
            }).map(e => mapAADUserToSfUser(e, p)));
            return r;
        }).then(r => {
            if (r.length > 0) {
                ReduxDispatch.showImportSuccess(true);
            }
        });
    }
}

const mapAADUserToSfUser = (user: User, p: Props) => {
    const ext = (user.businessPhones && user.businessPhones.length > 0) ? user.businessPhones[0] : undefined;
    const extension = ext ? getExtension(store.getState().setup.lineProperties.extBase, ext) : undefined;
    console.log("External Number: " + ext);
    console.log("Extension: " + extension);
    return {
        login: extension,
        familyName: user.surname,
        firstName: user.givenName,
        email: user.mail,
        intPhoneNumber: Number(extension) >= 10 ? extension : undefined,
        extPhoneNumber: extension,
        password: p.commonDefaults.password,
        faxSender: p.commonDefaults.faxSender,
        license: "standard",
    } as UserProps
}

type Props = {
    accessToken: string | null
    commonDefaults: CommonProps,
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
    disabled?: boolean
}

export const AADImportUsersButton: React.FC<Props> = (p: Props) => {
    const { instance, accounts } = useMsal();
    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    if (!p.disabled && !p.accessToken) {
        instance.acquireTokenSilent(request).then((response) => {
            p.setAccessToken(response.accessToken);
        })
    }

    return <CompoundButton primary onClick={() => importUsers(p)} disabled={p.disabled}
                           iconProps={{iconName: "Group"}} secondaryText={"aus Azure Active Directory"}>
        Benutzer importieren</CompoundButton>
}

async function handleResponse(response: Response, to: "blob" | "json") {
    if (response.ok) {
        switch (to) {
            case "json":
                return response.json();
            case "blob":
                return response.blob();
        }
    } else {
        // throwing an error won't work in async callbacks (only works in promise callbacks)
        return Promise.reject("Request failed with status: " + response.status)
    }
}