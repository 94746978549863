import * as React from "react";
import {useEffect} from "react";
import './App.css';
import {PcSetupUserForm} from "./components/PcSetupUserForm";
import {mergeStyles, PartialTheme, ThemeProvider} from "@fluentui/react";
import {PcSfPartner} from "./components/PcSfPartner";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "./components/RootReducer";
import {Dispatch} from "redux";
import {useUrlSearchParams} from "use-url-search-params";
import {PcImportDialog} from "./components/PcImportDialog";
import {PcPartnerDialog} from "./components/PcPartnerDialog";
import {PcUtpExportDialog} from "./components/PcUtpExportDialog";
import {ReduxDispatch} from "./components/ReduxStore";

const appTheme: PartialTheme = {
    palette: {
        themePrimary: '#f59c00',
    },
    semanticColors: {
        link:  '#f59c00',
        actionLink: '#f59c00',
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        setup: state.setup,
        ui: state.ui
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
};

type ConnectorProps = ConnectedProps<typeof connector>

export type AppProps = ConnectorProps & {}

const App = (p: AppProps) => {
  const [urlParams] = useUrlSearchParams({p: "", prj: "", c: ""}, {p: String, prj: String, c: String});
  let theme = appTheme;
  if (urlParams.c) {
      theme = {...appTheme, ...{
          palette: {
              themePrimary: "#" + urlParams.c,
              accent: "#" + urlParams.c,
          },
          semanticColors: {
              link:  "#" + urlParams.c,
              linkHovered:  "#" + urlParams.c + "bb",
              buttonBackgroundCheckedHovered: "#" + urlParams.c + "bb",
              primaryButtonBackgroundHovered: "#" + urlParams.c + "bb",
              actionLink: "#" + urlParams.c,
          }
        }
      }
  }

  useEffect(() => {
      if (!urlParams.p || urlParams.p === "") {
          ReduxDispatch.showPartnerDialog(true);
      }
  }, [urlParams.p])

    console.log("partnerDialog: " + p.ui.partnerDialogVisible);
    console.log("importDialog: " + p.ui.importDialogVisible);
    console.log("exportDialog: " + p.ui.exportUtpDialogVisible);

    return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <div className={"step"} style={{border: "1px solid " + theme.palette?.themePrimary, boxShadow: "rgb(190 190 190) 2px 2px 6px, rgb(255 255 255) -2px -2px 6px"}}>
          <PcSfPartner theme={theme}/>
          <PcSetupUserForm setup={p.setup}/>

          <PcPartnerDialog hidden={!p.ui.partnerDialogVisible} setup={p.setup}/>
          <PcImportDialog hidden={!p.ui.importDialogVisible} setup={p.setup}/>
          <PcUtpExportDialog hidden={!p.ui.exportUtpDialogVisible} setup={p.setup}/>
      </div>
    </div>
    </ThemeProvider>
  );
}

export const paragraphStyleClass = mergeStyles({
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    //margin: "0 auto 0 auto"
})

//export default App;
const connector = connect(mapStateToProps, mapDispatchToProps)
export const CcApp = connector(App);